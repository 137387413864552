import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes.js'

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {  el: to.hash,
        behavior: 'smooth', }

    }
    if (savedPosition) {
      return savedPosition
    }
    if (to.meta.position) {
      return savedPosition
    }

    return { top: 0 }
  },
})

export default router
