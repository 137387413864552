<template>
  <div>
    <component
      :is="currentIcon"
      v-bind="{ ...$props, ...$attrs }"
    />
  </div>
</template>

<script>


import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const currentIcon = shallowRef('')
    import(`./${props.icon}.vue`).then((val) => {
      currentIcon.value = val.default
    })

    return {
      currentIcon,
    }
  },
})
</script>
