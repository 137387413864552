const generalConstants = {
  imageSize: 500,
  RECORD_LIMIT: 10,
  RECORD_SKIP: 0,
  NOT_AVAILABLE: 'N/A',
  PASS: 'pass',
  FAIL: 'fail',
  NO_RECORD_fOUND: 'No Record found',
  DAY_RANGE: ['monday', 'sunday'],
  TIME_RANGE: ['0:00 am', '0:00 am'],
  WEEK_START_DAY: 'Monday',
  MONTH: 'Month',
  WEEK: 'Week',
  DAY: 'Day',
  MYQAMPUS_VIDEO: 'https://www.youtube.com/embed/y2_80BoV-rY?rel=0&loop=1',
}
export const ROLES_LIST = [
  { id: '0', name: 'Student' },
  { id: '1', name: 'All Student' },
  { id: '2', name: 'Teacher' },
  { id: '3', name: 'All Teacher' },
  { id: '4', name: 'Gaurdian' },
  { id: '5', name: 'All Gaurdian' },
  { id: '6', name: 'Staff' },
  { id: '7', name: 'All Staff' },
]

export const MEDIUM_LIST = [
  { id: '1', name: 'Email' },
  { id: '2', name: 'Sms' },
  { id: '3', name: 'Web Push Notifications' },
]

/**
 * User Constants
 */
export const USER_CONSTANTS = {
  GENDERS: [
    { title: 'MALE', value: 'male' },
    { title: 'FEMALE', value: 'female' },
    { title: 'OTHERS', value: 'others' },
    { title: 'NOT_SELECTED', value: '' },
  ],
  LOGIN_ENABLED_STATUSES: [
    { title: 'YES', value: true },
    { title: 'NO', value: false },
  ],
}

export const navbarLinks = [
  { to: '/features', activeNavBarLink: 'Features', name: 'Features' },
  { to: '/pricing', activeNavBarLink: 'pricing', name: 'Pricing' },
  { to: '/about-us', activeNavBarLink: 'About_Us', name: 'About Us' },
]

export const NOTIFICATION_STATUS = {
  READ: 'Read',
  UNREAD: 'Unread',
  READ_ALL_SMALL: 'read',
  UNREAD_ALL_SMALL: 'unread',
}

export const COMMUNICATION_MEDIUMS = {
  WEBPUSH: 'webpush',
  SMS: 'sms',
  EMAIL: 'email',
}

export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const DEFAULT_LANGUAGE = {
  id: 0,
  short: 'en',
  long: 'English',
  dir: 'ltr',
}

export default generalConstants
